@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url("https://use.typekit.net/mvt3kqs.css");
@import "../../vendor/spatie/laravel-medialibrary-pro/resources/js/media-library-pro-styles";

[x-cloak] {
    display: none;
}

html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: "Avenir Next";
    src: url("/fonts/56b59602-81b2-45db-91e1-da92d39f3ebc.woff2") format("woff2"), url("/fonts/2f2e081e-91c9-4952-81fe-c299420d928a.woff") format("woff");
    font-style: normal;
}

@font-face {
    font-family: "Avenir Next";
    src: url("/fonts/cf7047b5-ced0-4cb1-b66e-688e29567e96.woff2") format("woff2"), url("/fonts/8a2ab6e1-4ba1-439f-a73b-29918942af3b.woff") format("woff");
    font-style: italic;
}


.btn-lg {
    @apply bg-primary-600 text-white tracking-widest uppercase px-3 py-2 whitespace-nowrap;
}

.invisible-scrollbar::-webkit-scrollbar {
    display: none;
}

.size-table table {
    @apply min-w-full border border-gray-200 divide-y divide-gray-300;
}

.size-table table thead {
    @apply bg-gray-50;
}

.size-table table tbody {
    @apply divide-y divide-gray-200 bg-white;
}

.size-table table tr {
    @apply px-4 divide-x divide-gray-200;
}

.size-table table th {
    @apply whitespace-nowrap px-2 py-2 text-center text-sm font-semibold text-gray-900;
}

.size-table table td {
    @apply whitespace-nowrap text-center px-2 py-2 text-sm text-gray-500;
}

.size-table table tr td:first-of-type {
    @apply text-left;
}